import React, { useEffect, useState } from 'react';
import "@dnb/eufemia/style";
import Layout from "../components/layout";
import {P, Table} from "@dnb/eufemia"
import { CustomButton } from '../components/styledComponents';
import { Asset } from "../types/Asset"
import axios from 'axios';
import { navigate } from 'gatsby';

const API = process.env.API_ENDPOINT

const getAssets = () => {
    return fetch(`${API}/weatherforecast`)
      .then(data => data.json())
  }

const createPurchaseOrder = async (assetCode: string) => {
  const body = JSON.stringify({ 
    assetCode: assetCode,
    amount: 100, 
  })
  return axios
      .post(`${API}/purchaseorder`, body, {
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("access_token")}`
        },
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log('error in request:', err);
      })
      
}

const MarketplacePage = () => {
    const [assets, setAssets] = useState<Array<Asset>>([]);

    useEffect(() => {
        getAssets()
          .then(items => {
            setAssets(items)
          })
      }, [])

    return(
        <Layout>
        <h1>Marketplace</h1>
        <P>Today on the market place we sell: </P>
        <P>Crypto of the best quality at low low prices</P>

        <br/>

        <Table style={{border: 1}}> 
          <thead>
            <tr>
              <th>Asset</th>
              <th>Price</th>
              <th>Buy</th>
            </tr>
          </thead>
          <tbody>
          {
             assets.map((item) => 
              (<tr>
              <td>{item.name}</td>
              <td>{item.price.value}</td>
              <td>
                <CustomButton 
                  onClick={() => navigate(`/buy?asset=${item.code}`)}>Buy</CustomButton>
              </td>
            </tr>)
            )
          }
          </tbody>
        </Table>

        
        <ul>
            
        </ul>
        </Layout>
    )
}

export default MarketplacePage;